<template>
  <div
    id="search"
    class="my-80 my-md-60"
  >
    <div class="home-search__inner">
      <UiHeadline
        lvl="2"
        class="home-search__title"
      >
        Быстро найдем ответ на&nbsp;ваш вопрос
      </UiHeadline>

      <input
        type="text"
        v-model="search"
        class="home-search__field"
        placeholder="Найти"
      >

      <div v-if="pending" class="home-search__pending">
        <UiLoading />
      </div>
      <template v-else>
        <AppExpand
          v-for="item in dataFaq"
          :key="item.id"
          v-bind="item"
          class="home-search__collapse"
        />
        <div v-if="!isFounded" class="home-search__empty">
          <div class="home-search__empty-title">
            Ничего не нашли
          </div>
          <div class="home-search__empty-subtitle">
            Переформулируйте Ваш вопрос
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { search, pending, isFounded, dataFaq } = await useSearchFaq('upravlenie-lichnym-kabinetom-ppr')
</script>

<style lang="scss" scoped>
.home-search {
  &__inner {
    max-width: 1056px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__title {
    text-align: center;
  }

  &__field {
    width: 100%;
    height: 56px;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 44px;
    padding-right: 44px;
    font-size: 14px;
    font-weight: 400;
    line-height: 145%;
    color: #838B93;
    border: none;
    background-color: #f6f5f5;
    border-radius: 8px;
    background-image: url('/images/common/icons/search.svg');
    background-repeat: no-repeat;
    background-position: 16px center;

    &::placeholder {
      color: #838B93;
    }

    @include media-down(md) {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  &__collapse {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-down(md) {
      margin-bottom: 24px;
    }
  }

  &__pending {
    display: flex;
    justify-content: center;
  }

  &__empty {
    text-align: center;
  }

  &__empty-title {
    font-size: 16px;
    line-height: 145%;
    font-weight: 500;
  }

  &__empty-subtitle {
    opacity: .8;
  }
}
</style>
