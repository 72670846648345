<script setup>
const params = [
  {key: 'utm_source', value: 'mypetrolplus'},
  {key: 'utm_medium', value: 'referral'},
  {key: 'utm_campaign', value: 'inner-referral-program'},
];
</script>

<template>
  <div id="referral-form" class="my-80 my-md-60">
    <AppContainer>
      <UiFormReferralWrapper
        formId="c6fa4b46-65c3-46b9-9bfb-78e09c5a1a86"
        :utmParams="params"
        @copied="$emit('copied')"
      />
    </AppContainer>
  </div>
</template>
