export const useSearchFaq = async(defaultSlugCategory: string) => {
  const { getItems } = useDirectusItems()

  const search = ref('')
  const searchOnce = ref(false)
  const searchDebounced = refDebounced(search, 400)

  watchOnce(searchDebounced, () => searchOnce.value = true)

  const defaultSearchCategory = {
    categories: { faq_categories_id: { slug: defaultSlugCategory } },
  }

  const { data: dataFaq, pending } = await useAsyncData('faq', () =>
    getItems<unknown[]>({
      collection: 'faq',
      params: {
        fields: ['question', 'answer', 'id'],
        filter: {
          ...searchDebounced.value
            ? { question: { _icontains: searchDebounced.value } }
            : defaultSearchCategory,
          _and: [
            {
              categories: {
                _and: [
                  { faq_categories_id: { show_in_menu: true } },
                  { faq_categories_id: { show_in_search: true } },
                ],
              },
            },
            {
              categories: {
                _or: [
                  { faq_categories_id: { parent: { _null: null } } },
                  { faq_categories_id: { parent: { show_in_search: true } } },
                ],
              },
            },
            {
              categories: {
                _or: [
                  { faq_categories_id: { parent: { _null: null } } },
                  { faq_categories_id: { parent: { show_in_menu: true } } },
                ],
              },
            },
            {
              categories: {
                _or: [
                  { faq_categories_id: { parent: { parent: { _null: null } } } },
                  { faq_categories_id: { parent: { parent: { show_in_menu: true } } } },
                ],
              },
            },
            {
              categories: {
                _or: [
                  { faq_categories_id: { parent: { parent: { _null: null } } } },
                  { faq_categories_id: { parent: { parent: { show_in_search: true } } } },
                ],
              },
            },
          ],
        },
      },
    }), {
    watch: [searchDebounced],
  })

  const isFounded = computed(() => dataFaq.value?.length! > 0)

  return {
    search,
    pending,
    isFounded,
    dataFaq,
  }
}

export const useGetFaqCategories = () => {
  const { getItems } = useDirectusItems()

  return useAsyncData('faqCategories', () => getItems<any[]>({
    collection: 'faq_categories',
    params: {
      filter: {
        _and: [
          { show_in_menu: true },
          { show_in_search: true },
        ],
      },
      limit: -1,
    },
  }))
}

export const useFaqByCategories = (categories: string[]) => {
  const { getItems } = useDirectusItems()

  return useAsyncData('faqByCategories', () => getItems<unknown[]>({
    collection: 'faq',
    params: {
      fields: ['question', 'answer', 'id'],
      filter: {
        categories: { faq_categories_id: { _in: categories } },
      },
    },
  }))
}

export const useGetCurrentAndChild = (categories: any[], slug: string) => {
  const collections = []
  const category = categories.find(category => category.slug === slug)

  if (category === undefined) {
    return []
  }

  collections.push(category)

  const collectChildren = (id: string) => {
    categories.forEach(category => {
      if (category.parent === id
        && category.show_in_menu
        && category.show_in_search
      ) {
        collections.push(category)
        collectChildren(category.id)
      }
    })
  }

  collectChildren(category.id)

  return collections
}
