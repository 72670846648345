<template>
  <AppMain class="mb-80">
    <SectionManager :data="data[scope]" />
    <HomeReferral />
    <HomeSearch />
  </AppMain>
</template>

<script lang="ts" setup>
const scope = 'sections'

const { fetchSections } = useSectionManager()

const { data } = await fetchSections(
  'cs_main_page',
  {
    scope,
    fields: [...seoFields],
  },
)

usePageHead({
  seo: data.value.seo,
})
</script>
