<template>
  <div
    class="app-collapse"
    :class="{ 'app-collapse_open': isOpen }"
  >
    <div
      class="app-collapse__header"
      @click="isOpen = !isOpen"
    >
      <div
        class="app-collapse__title"
        v-html="question"
      />
      <div class="app-collapse__arrow"></div>
    </div>

    <div
      v-show="isOpen"
      v-html="answer"
      class="app-collapse__content wysiwyg-content"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

defineProps({
  question: {
    type: String,
    default: ''
  },
  answer: {
    type: String,
    default: ''
  },
})

const isOpen = ref<boolean>(false)
</script>

<style lang="scss" scoped>
.app-collapse {
  color: #111;

  &__header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
    user-select: none;
    padding-right: 48px;

    @include media-down(md) {
      padding-right: 8px;
      font-size: 18px;
    }
  }

  &__arrow {
    min-width: 24px;
    height: 24px;
    background-image: url('/images/common/icons/small-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
  }

  &__content {
    padding-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 145%;
  }

  &_open {
    .app-collapse__arrow {
      transform: rotate(0deg);
    }
  }
}
</style>
